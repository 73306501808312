@import "https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600;700&display=swap";
.bold {
  font-weight: 700;
}

.regular {
  font-weight: 500;
}

.light {
  font-weight: 300;
}

.italic {
  font-style: italic;
}

.normal {
  font-style: normal;
}

.content {
  width: 100%;
  margin-top: 180px;
}

.content > * {
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}

a > span {
  color: #4f4f4f;
  border-bottom: 1px solid #0000;
  transition: border-bottom .2s ease-in-out;
}

a > span:hover {
  border-bottom: 1px solid #4f4f4f;
}

.access-container {
  opacity: 0;
  background: none;
  flex-direction: column;
  width: 90%;
  max-width: 1000px;
  min-height: 100vh;
  transition: opacity .2s ease-in-out;
  display: flex;
}

.access-container[active] {
  opacity: 1;
  min-height: 0;
}

.access-container[fill="dark"] {
  background: #4f4f4f;
}

.access-container[fill="light"] {
  background: #ffffff6d;
}

.access-container[full] {
  width: 100%;
  max-width: 100%;
}

.access-container[padding="all"] {
  padding: 32px;
}

.access-container[padding="vertical"] {
  padding: 32px 0;
}

.access-container[padding="horizontal"] {
  padding: 0 32px;
}

.access-spacer {
  opacity: 0;
  box-sizing: border-box;
  color: #4f4f4f;
  text-align: justify;
  background: none;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 20px;
  transition: opacity .2s ease-in-out;
}

.access-spacer[active] {
  opacity: 1;
  min-height: 0;
}

.access-spacer[padding="all"] {
  padding: 32px;
}

.access-spacer[padding="vertical"] {
  padding: 32px 0;
}

.access-spacer[padding="horizontal"] {
  padding: 0 32px;
}

.access-spacer[color="light"] {
  color: #929292;
}

.access-spacer[color="dark"] {
  color: #4f4f4f;
}

.access-spacer[align="left"] {
  text-align: left;
}

.access-spacer[align="center"] {
  text-align: center;
}

.access-spacer[align="right"] {
  text-align: right;
}

.access-spacer[align="justify"] {
  text-align: justify;
}

.access-spacer[selectable] {
  -webkit-user-select: text;
  user-select: text;
}

.access-spacer[fill="dark"] {
  background: #4f4f4f;
}

.access-spacer[fill="light"] {
  background: #ffffff6d;
}

.access-text {
  opacity: 0;
  box-sizing: border-box;
  color: #4f4f4f;
  text-align: justify;
  white-space: pre-wrap;
  background: none;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 20px;
  transition: opacity .2s ease-in-out;
}

.access-text[active] {
  opacity: 1;
  min-height: 0;
}

.access-text[padding="all"] {
  padding: 32px;
}

.access-text[padding="vertical"] {
  padding: 32px 0;
}

.access-text[padding="horizontal"] {
  padding: 0 32px;
}

.access-text[color="light"] {
  color: #929292;
}

.access-text[color="dark"] {
  color: #4f4f4f;
}

.access-text[align="left"] {
  text-align: left;
}

.access-text[align="center"] {
  text-align: center;
}

.access-text[align="right"] {
  text-align: right;
}

.access-text[align="justify"] {
  text-align: justify;
}

.access-text[selectable] {
  -webkit-user-select: text;
  user-select: text;
}

.access-text[fill="dark"] {
  background: #4f4f4f;
}

.access-text[fill="light"] {
  background: #ffffff6d;
}

.access-text img {
  filter: invert(34%) sepia(11%) saturate(1221%) hue-rotate(174deg) brightness(92%) contrast(83%);
  max-width: 24px;
  max-height: 24px;
  margin: 0 4px;
  transform: scale(1.2);
}

.access-image {
  opacity: 0;
  box-sizing: border-box;
  color: #4f4f4f;
  text-align: justify;
  background: none;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-size: 20px;
  transition: opacity .2s ease-in-out;
}

.access-image[active] {
  opacity: 1;
  min-height: 0;
}

.access-image[padding="all"] {
  padding: 32px;
}

.access-image[padding="vertical"] {
  padding: 32px 0;
}

.access-image[padding="horizontal"] {
  padding: 0 32px;
}

.access-image[color="light"] {
  color: #929292;
}

.access-image[color="dark"] {
  color: #4f4f4f;
}

.access-image[align="left"] {
  text-align: left;
}

.access-image[align="center"] {
  text-align: center;
}

.access-image[align="right"] {
  text-align: right;
}

.access-image[align="justify"] {
  text-align: justify;
}

.access-image[selectable] {
  -webkit-user-select: text;
  user-select: text;
}

.access-image[fill="dark"] {
  background: #4f4f4f;
}

.access-image[fill="light"] {
  background: #ffffff6d;
}

.access-image > div {
  margin: 0 auto;
}

.access-image > div[align="left"] {
  margin: 0 auto 0 0;
}

.access-image > div[align="center"] {
  margin: 0 auto;
}

.access-image > div[align="right"] {
  margin: 0 0 0 auto;
}

.access-image > div > div {
  display: grid;
}

.access-image img {
  opacity: 0;
  width: 100%;
  min-height: 100vh;
  transition: opacity .2s ease-in-out;
}

.access-image img[active] {
  opacity: 1;
  min-height: 0;
}

.access-texted-image {
  opacity: 0;
  box-sizing: border-box;
  color: #4f4f4f;
  text-align: justify;
  background: none;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-size: 20px;
  transition: opacity .2s ease-in-out;
  display: flex;
}

.access-texted-image[active] {
  opacity: 1;
  min-height: 0;
}

.access-texted-image[padding="all"] {
  padding: 32px;
}

.access-texted-image[padding="vertical"] {
  padding: 32px 0;
}

.access-texted-image[padding="horizontal"] {
  padding: 0 32px;
}

.access-texted-image[color="light"] {
  color: #929292;
}

.access-texted-image[color="dark"] {
  color: #4f4f4f;
}

.access-texted-image[align="left"] {
  text-align: left;
}

.access-texted-image[align="center"] {
  text-align: center;
}

.access-texted-image[align="right"] {
  text-align: right;
}

.access-texted-image[align="justify"] {
  text-align: justify;
}

.access-texted-image[selectable] {
  -webkit-user-select: text;
  user-select: text;
}

.access-texted-image[fill="dark"] {
  background: #4f4f4f;
}

.access-texted-image[fill="light"] {
  background: #ffffff6d;
}

@media (width <= 1200px) {
  .access-texted-image {
    flex-direction: column;
  }

  .access-texted-image [role="image"] {
    order: 2;
    margin: 0 auto;
  }

  .access-texted-image [role="text"] {
    order: 1;
    width: 100% !important;
  }
}

.access-professor-info {
  opacity: 0;
  box-sizing: border-box;
  color: #4f4f4f;
  text-align: justify;
  white-space: pre-wrap;
  background: none;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-size: 20px;
  transition: opacity .2s ease-in-out;
  display: flex;
}

.access-professor-info[active] {
  opacity: 1;
  min-height: 0;
}

.access-professor-info[padding="all"] {
  padding: 32px;
}

.access-professor-info[padding="vertical"] {
  padding: 32px 0;
}

.access-professor-info[padding="horizontal"] {
  padding: 0 32px;
}

.access-professor-info[color="light"] {
  color: #929292;
}

.access-professor-info[color="dark"] {
  color: #4f4f4f;
}

.access-professor-info[align="left"] {
  text-align: left;
}

.access-professor-info[align="center"] {
  text-align: center;
}

.access-professor-info[align="right"] {
  text-align: right;
}

.access-professor-info[align="justify"] {
  text-align: justify;
}

.access-professor-info[selectable] {
  -webkit-user-select: text;
  user-select: text;
}

.access-professor-info[fill="dark"] {
  background: #4f4f4f;
}

.access-professor-info[fill="light"] {
  background: #ffffff6d;
}

@media (width <= 1200px) {
  .access-professor-info {
    flex-direction: column;
  }
}

.access-professor-info [role="image"] {
  width: 40%;
}

@media (width <= 1200px) {
  .access-professor-info [role="image"] {
    width: 100%;
  }
}

.access-professor-info [role="text"] {
  -webkit-user-select: text;
  user-select: text;
  flex-direction: column;
  flex: auto;
  justify-content: space-around;
  width: 60%;
  margin: 12px 0;
  display: flex;
}

@media (width <= 1200px) {
  .access-professor-info [role="text"] {
    width: 100%;
    margin: 24px 0;
  }
}

.access-professor-info [role="text"] [role="name"] {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}

.access-professor-info [role="text"] [role="title"] {
  text-align: center;
  color: #929292;
  font-size: 24px;
  font-weight: 500;
}

.access-professor-info [role="text"] [role="name_title"] {
  margin-bottom: 24px;
}

@media (width <= 1200px) {
  .access-professor-info [role="text"] [role="name_title"] {
    margin-bottom: 36px;
  }
}

.access-professor-info table {
  white-space: pre-wrap;
  max-width: 80%;
  margin: 24px auto 0;
}

@media (width <= 1200px) {
  .access-professor-info table {
    margin-top: 36px;
  }
}

.access-professor-info table img {
  filter: invert(34%) sepia(11%) saturate(1221%) hue-rotate(174deg) brightness(92%) contrast(83%);
  max-width: 28px;
  max-height: 28px;
  margin: 0 12px;
}

.access-professor-info table span {
  color: #4f4f4f;
  margin-right: 40px;
  font-size: 16px;
  font-weight: 500;
}

.access-infocard {
  opacity: 0;
  box-sizing: border-box;
  color: #4f4f4f;
  text-align: justify;
  background: none;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 18px 24px;
  font-size: 20px;
  transition: opacity .2s ease-in-out;
}

.access-infocard[active] {
  opacity: 1;
  min-height: 0;
}

.access-infocard[padding="all"] {
  padding: 32px;
}

.access-infocard[padding="vertical"] {
  padding: 32px 0;
}

.access-infocard[padding="horizontal"] {
  padding: 0 32px;
}

.access-infocard[color="light"] {
  color: #929292;
}

.access-infocard[color="dark"] {
  color: #4f4f4f;
}

.access-infocard[align="left"] {
  text-align: left;
}

.access-infocard[align="center"] {
  text-align: center;
}

.access-infocard[align="right"] {
  text-align: right;
}

.access-infocard[align="justify"] {
  text-align: justify;
}

.access-infocard[selectable] {
  -webkit-user-select: text;
  user-select: text;
}

.access-infocard[fill="dark"] {
  background: #4f4f4f;
}

.access-infocard[fill="light"] {
  background: #ffffff6d;
}

.access-infocard [role="title"] {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.access-infocard [role="description"] {
  text-align: justify;
}

.access-infogroup {
  opacity: 0;
  box-sizing: border-box;
  color: #4f4f4f;
  text-align: justify;
  background: none;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 20px;
  transition: opacity .2s ease-in-out;
}

.access-infogroup[active] {
  opacity: 1;
  min-height: 0;
}

.access-infogroup[padding="all"] {
  padding: 32px;
}

.access-infogroup[padding="vertical"] {
  padding: 32px 0;
}

.access-infogroup[padding="horizontal"] {
  padding: 0 32px;
}

.access-infogroup[color="light"] {
  color: #929292;
}

.access-infogroup[color="dark"] {
  color: #4f4f4f;
}

.access-infogroup[align="left"] {
  text-align: left;
}

.access-infogroup[align="center"] {
  text-align: center;
}

.access-infogroup[align="right"] {
  text-align: right;
}

.access-infogroup[align="justify"] {
  text-align: justify;
}

.access-infogroup[selectable] {
  -webkit-user-select: text;
  user-select: text;
}

.access-infogroup[fill="dark"] {
  background: #4f4f4f;
}

.access-infogroup[fill="light"] {
  background: #ffffff6d;
}

.access-infogroup > [role="title"] {
  text-align: center;
  border-bottom: 3px solid #4f4f4f;
  font-size: 24px;
  font-weight: 700;
}

.access-404 {
  opacity: 0;
  box-sizing: border-box;
  color: #4f4f4f;
  text-align: justify;
  background: none;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 20px;
  transition: opacity .2s ease-in-out;
  display: flex;
}

.access-404[active] {
  opacity: 1;
  min-height: 0;
}

.access-404[padding="all"] {
  padding: 32px;
}

.access-404[padding="vertical"] {
  padding: 32px 0;
}

.access-404[padding="horizontal"] {
  padding: 0 32px;
}

.access-404[color="light"] {
  color: #929292;
}

.access-404[color="dark"] {
  color: #4f4f4f;
}

.access-404[align="left"] {
  text-align: left;
}

.access-404[align="center"] {
  text-align: center;
}

.access-404[align="right"] {
  text-align: right;
}

.access-404[align="justify"] {
  text-align: justify;
}

.access-404[selectable] {
  -webkit-user-select: text;
  user-select: text;
}

.access-404[fill="dark"] {
  background: #4f4f4f;
}

.access-404[fill="light"] {
  background: #ffffff6d;
}

.access-404 [role="404"] {
  text-align: center;
  width: 100%;
  font-size: 160px;
  font-weight: 700;
}

.access-404 > div {
  justify-content: space-around;
  display: flex;
}

.access-404 > div [role="text"] {
  font-size: 60px;
  font-weight: 500;
}
/*# sourceMappingURL=error.59cca697.css.map */
