@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;600;700&display=swap')

$navbar_offset: 110px
$color_bg: #dce2e3
$color_card_bg: #ffffff6d
$color_nav_bg: #6594C2
$color_shadow: #00000070
$color_font_dark: #4F4F4F
$color_font_light: #929292
$collapsed-width: 1200px

@mixin mobile
    @media(max-width: 1200px)
        @content

@mixin tablet
    @media(max-width: 1600px)
        @content

@mixin desktop
    @content

@mixin collapsed
    @media(max-width: $collapsed-width)
        @content

@mixin card
    @include loading-animation
    background: transparent
    &[full]
        width: 100%
        max-width: 100%
    &[fill]
        background: $color_card_bg

@mixin icon-tint
    filter: invert(34%) sepia(11%) saturate(1221%) hue-rotate(174deg) brightness(92%) contrast(83%)

@mixin icon-color
    filter: invert(87%) sepia(1%) saturate(0%) hue-rotate(185deg) brightness(95%) contrast(85%)

@mixin component-container
    width: 90%
    max-width: 1000px
    margin: 0 auto

@mixin recognize-linebreak
    white-space: pre-wrap

@mixin allow-select
    user-select: text

@mixin disallow-select
    user-select: none

@mixin vertical-arrange
    display: flex
    flex-direction: column
    justify-content: space-between

@mixin font-context
    font-size: 18
    font-weight: 500
    color: $color_font_dark

@mixin hover-underline-light
    transition: border-bottom 0.2s ease-in-out
    border-bottom: 1px solid transparent
    &:hover
        border-bottom: 1px solid white

@mixin hover-underline-dark
    transition: border-bottom 0.2s ease-in-out
    border-bottom: 1px solid transparent
    &:hover
        border-bottom: 1px solid $color_font_dark

@mixin loading-animation
    transition: opacity 0.2s ease-in-out
    opacity: 0
    min-height: 100vh
    &[active]
        min-height: 0
        opacity: 1

.bold
    font-weight: 700

.regular
    font-weight: 500

.light
    font-weight: 300

.italic
    font-style: italic

.normal
    font-style: normal

.content
    margin-top: 180px
    width: 100%
    // @include collapsed
        // margin-top: 70px
    > *
        margin-left: auto
        margin-right: auto

a
    text-decoration: none
    > span
        @include hover-underline-dark
        color: $color_font_dark