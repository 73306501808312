@import './base.sass'

.access-container
    @include loading-animation
    background: transparent
    display: flex
    flex-direction: column
    width: 90%
    max-width: 1000px
    &[fill=dark]
        background: $color_font_dark
    &[fill=light]
        background: $color_card_bg
    &[full]
        width: 100%
        max-width: 100%
    &[padding=all]
        padding: 32px
    &[padding=vertical]
        padding: 32px 0
    &[padding=horizontal]
        padding: 0 32px

@mixin access-card
    @include loading-animation
    box-sizing: border-box
    background: transparent
    width: 100%
    &[padding=all]
        padding: 32px
    &[padding=vertical]
        padding: 32px 0
    &[padding=horizontal]
        padding: 0 32px
    margin: 0 auto
    color: $color_font_dark
    font-size: 20
    text-align: justify
    &[color=light]
        color: $color_font_light
    &[color=dark]
        color: $color_font_dark
    &[align=left]
        text-align: left
    &[align=center]
        text-align: center
    &[align=right]
        text-align: right
    &[align=justify]
        text-align: justify
    &[selectable]
        @include allow-select
    &[fill=dark]
        background: $color_font_dark
    &[fill=light]
        background: $color_card_bg

.access-spacer
    @include access-card

.access-text
    @include access-card
    @include recognize-linebreak
    img
        @include icon-tint
        max-height: 24
        max-width: 24
        transform: scale(1.2)
        margin: 0 4px

.access-image
    @include access-card
    padding: 0
    > div
        margin: 0 auto
        &[align=left]
            margin: 0 auto 0 0
        &[align=center]
            margin: 0 auto
        &[align=right]
            margin: 0 0 0 auto
        > div
            display: grid
    img
        width: 100%
        @include loading-animation

.access-texted-image
    @include access-card
    display: flex
    padding: 0
    @include mobile
        flex-direction: column
    [role=image]
        @include mobile
            order: 2
            margin: 0 auto
    [role=text]
        @include mobile
            order: 1
            width: 100% !important

.access-professor-info
    @include access-card
    @include recognize-linebreak
    display: flex
    padding: 0
    @include mobile
        flex-direction: column
    [role=image]
        width: 40%
        @include mobile
            width: 100%
    [role=text]
        @include allow-select
        width: 60%
        margin: 12px 0
        @include mobile
            width: 100%
            margin: 24px 0
        flex: auto
        display: flex
        flex-direction: column
        justify-content: space-around
        [role=name]
            text-align: center
            font-size: 28
            font-weight: 700
        [role=title]
            text-align: center
            color: $color_font_light
            font-size: 24
            font-weight: 500
        [role=name_title]
            margin-bottom: 24px
            @include mobile
                margin-bottom: 36px
    table
        @include recognize-linebreak
        margin: 0 auto
        margin-top: 24px
        max-width: 80%
        @include mobile
            margin-top: 36px
        img
            @include icon-tint
            max-width: 28
            max-height: 28
            margin: 0 12px
        span
            color: $color_font_dark
            margin-right: 40px
            font-size: 16
            font-weight: 500

.access-infocard
    @include access-card
    padding: 18px 24px
    [role=title]
        text-align: center
        font-size: 24
        font-weight: 700
    [role=description]
        text-align: justify
        
.access-infogroup
    @include access-card
    > [role=title]
        text-align: center
        font-size: 24
        font-weight: 700
        border-bottom: 3px solid $color_font_dark