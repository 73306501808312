@import "../Basic"

.access-404
    @include access-card
    display: flex
    flex-direction: column
    [role="404"]
        width: 100%
        text-align: center
        font-size: 160
        font-weight: 700
    > div
        display: flex
        justify-content: space-around
        [role=text]
            font-size: 60
            font-weight: 500
